import auth            from '@/app/middlewares/auth'
import routePermission from '@/app/middlewares/permission'
import guest           from '@/app/middlewares/guest'

import DefaultLayout from '@/views/layouts/Default'

const routes = [
    {
        path: '/login',
        component: () => import('@/views/pages/auth/Login'),
        name: 'login',
        meta: {
            middleware: [guest],
        },
    },
    {
        path: '/forget-password',
        component: () => import('@/views/pages/auth/ForgetPassword'),
        name: 'forget-password',
        meta: {
            middleware: [guest],
        },
    },
    {
        path: '/reset-password/:token',
        component: () => import('@/views/pages/auth/ResetPassword'),
        name: 'reset-password',
        meta: {
            middleware: [guest],
        },
    },
    {
        path: '/lucky-draws/:slug/register',
        component: () => import('@/views/pages/marketing/lucky-draws/CreatePublicUser'),
        name: 'register.luckydraw.user',
        meta: {
            middleware: [guest],
        },
    },
    {
        path: '/logout',
        component: () => import('@/views/pages/Logout'),
        name: 'logout',
        meta: {
            middleware: [auth],
        },
    },
    {
        path: '/',
        component: DefaultLayout,
        meta: {
            middleware: [auth],
        },
        children: [
            {
                path: '/dashboard',
                component: () => import('@/views/pages/dashboard/index'),
                name: 'dashboard',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: '/sales/dashboard',
                component: () => import('@/views/pages/dashboard/sales_dashboard'),
                name: 'sales_dashboard',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: '/dashboard/creditors',
                component: () => import('@/views/pages/dashboard/creditors_dashboard'),
                name: 'creditors_dashboard',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: '/dashboard/approve',
                component: () => import('@/views/pages/dashboard/approveRequestList'),
                name: 'dashboard.approve',
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: '/profile',
                component: () => import('@/views/pages/auth/Profile'),
                name: 'profile',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: '/',
                redirect: '/dashboard',
            },
            {
                path: '/:catchAll(.*)',
                component: () => import('@/views/pages/PageNotFound'),
                name: 'notFound',
            },
        ],
    },

    //=========================sales quotations
    {
        path: '/sales/',
        component: DefaultLayout,
        children: [
            {
                path: 'pos',
                name: 'sales.pos.main',
                component: () => import('@/views/pages/sales/pos/Main'),
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'pos/:id/complete',
                name: 'sales.pos.complete',
                component: () => import('@/views/pages/sales/pos/PrintInvoice'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'pos-transactions',
                name: 'sales.pos.transactions',
                component: () => import('@/views/pages/sales/pos/List'),
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'quotations',
                name: 'sales.quotations.list',
                component: () => import('@/views/pages/sales/quotations/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'quotations/create',
                name: 'sales.quotations.create',
                component: () =>
                    import('@/views/pages/sales/quotations/Create'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'quotations/:id/edit',
                name: 'sales.quotations.edit',
                component: () => import('@/views/pages/sales/quotations/Create'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'proforma-invoices',
                name: 'sales.proforma-invoice.list',
                component: () => import('@/views/pages/sales/proforma-invoices/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'proforma-invoices/create',
                name: 'sales.proforma-invoice.create',
                component: () =>
                    import('@/views/pages/sales/proforma-invoices/Create'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'proforma-invoices/:id/edit',
                name: 'sales.proforma-invoice.edit',
                component: () => import('@/views/pages/sales/proforma-invoices/Create'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'sales-orders',
                name: 'sales.sales-orders.list',
                component: () => import('@/views/pages/sales/sales-orders/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'sales-orders-analysis-list',
                name: 'sales.sales-orders.analysis-list',
                component: () => import('@/views/pages/sales/sales-orders/AnalysisList'),
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'sales-orders/:id/edit',
                name: 'sales.sales-orders.edit',
                component: () => import('@/views/pages/sales/sales-orders/Create'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'ecommerce-invoices',
                name: 'sales.ecommerce-invoices.list',
                component: () => import('@/views/pages/sales/ecommerce-invoices/List'),
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'ecommerce-invoices/orders-not-converted',
                name: 'sales.ecommerce-invoices.orders-not-converted.list',
                component: () => import('@/views/pages/sales/ecommerce-invoices/orders-not-converted/List'),
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'ecommerce-invoice/:id/edit',
                name: 'sales.ecommerce-invoice.edit',
                component: () => import('@/views/pages/sales/ecommerce-invoices/Create'),
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'invoices',
                name: 'sales.invoices.list',
                component: () => import('@/views/pages/sales/invoices/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'invoices/:id/edit',
                name: 'sales.invoices.edit',
                component: () => import('@/views/pages/sales/invoices/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'invoices-analysis-list',
                name: 'sales.invoices-analysis-list',
                component: () => import('@/views/pages/sales/invoices/AnalysisList'),
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'delivery-orders',
                name: 'sales.delivery-orders.list',
                component: () => import('@/views/pages/sales/delivery-orders/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'delivery-orders/:id/edit',
                name: 'sales.delivery-orders.edit',
                component: () => import('@/views/pages/sales/delivery-orders/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'consignment',
                name: 'sales.consignment.main',
                component: () => import('@/views/pages/sales/consignment/Main'),
                meta: {
                    middleware: [auth],
                },
            },


        ],
    },

    //================= accounts
    {
        path: '/accounts/',
        component: DefaultLayout,
        children: [
            {
                path: 'customers',
                component: () =>
                    import('@/views/pages/accounts/customers/List'),
                name: 'accounts.customers.list',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'customers/create',
                component: () =>
                    import('@/views/pages/accounts/customers/Form'),
                name: 'accounts.customers.create',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'customers/:id/edit',
                component: () =>
                    import('@/views/pages/accounts/customers/Form'),
                name: 'accounts.customers.edit',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'supplier',
                component: () =>
                    import('@/views/pages/accounts/supplier/List'),
                name: 'accounts.supplier.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'supplier/create',
                component: () =>
                    import('@/views/pages/accounts/supplier/Form'),
                name: 'accounts.supplier.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'supplier/:id/edit',
                component: () =>
                    import('@/views/pages/accounts/supplier/Form'),
                name: 'accounts.supplier.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'companies',
                component: () =>
                    import('@/views/pages/accounts/companies/List'),
                name: 'accounts.companies.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'companies/create',
                component: () =>
                    import('@/views/pages/accounts/companies/Form'),
                name: 'accounts.companies.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'companies/:id/edit',
                component: () =>
                    import('@/views/pages/accounts/companies/Form'),
                name: 'accounts.companies.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'credit-notes',
                name: 'accounts.credit-notes.list',
                component: () => import('@/views/pages/accounts/credit-notes/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'credit-notes/create',
                name: 'accounts.credit-notes.create',
                component: () => import('@/views/pages/accounts/credit-notes/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'credit-notes/:id/edit',
                name: 'accounts.credit-notes.edit',
                component: () => import('@/views/pages/accounts/credit-notes/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'debit-notes',
                name: 'accounts.debit-notes.list',
                component: () => import('@/views/pages/accounts/debit-notes/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'debit-notes/create',
                name: 'accounts.debit-notes.create',
                component: () => import('@/views/pages/accounts/debit-notes/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'debit-notes/:id/edit',
                name: 'accounts.debit-notes.edit',
                component: () => import('@/views/pages/accounts/debit-notes/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'account-payables',
                name: 'accounts.account-payables.list',
                component: () => import('@/views/pages/accounts/account-payables/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'account-payables/create',
                name: 'accounts.account-payables.create',
                component: () => import('@/views/pages/accounts/account-payables/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'account-payables/:id/edit',
                name: 'accounts.account-payables.edit',
                component: () => import('@/views/pages/accounts/account-payables/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'consignment-notes',
                name: 'accounts.consignment-notes.list',
                component: () => import('@/views/pages/accounts/consignment-notes/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

        ],
    },

    //=============== items
    {
        path: '/items/',
        component: DefaultLayout,
        children: [
            {
                path: 'item',
                component: () => import('@/views/pages/items/item/List'),
                name: 'items.item.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item/item-list-details',
                component: () => import('@/views/pages/items/item/ListDetails'),
                name: 'items.item.list-details',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'item/create',
                component: () => import('@/views/pages/items/item/Form'),
                name: 'items.item.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item/sales-report',
                component: () => import('@/views/pages/items/item/SalesReport'),
                name: 'items.item.sales-report',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item/:id/edit',
                component: () => import('@/views/pages/items/item/Form'),
                name: 'items.item.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item/:id/inventory',
                component: () => import('@/views/pages/items/item/Inventory'),
                name: 'items.item.inventory',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item/:id/carton-inventory',
                component: () => import("@/views/pages/items/item/CartonInventory"),
                name: 'items.item.carton-inventory',
                meta: {
                    middleware: [auth, routePermission]
                }
            },
            {
                path: 'item/:id/item-stats',
                component: () => import("@/views/pages/items/item/ItemStats"),
                name: 'items.item.item-stats',
                meta: {
                    middleware: [auth, routePermission]
                }
            },
            {
                path: 'item/:id/sales-graph',
                component: () => import("@/views/pages/items/item/SalesGraph"),
                name: 'items.item.sales-graph',
                meta: {
                    middleware: [auth, routePermission]
                }
            },
            {
                path: 'quick-edit-items',
                component: () => import("@/views/pages/items/quick-edit-items/List"),
                name: 'items.quick-edit-items.list',
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'quick-edit-price-group-items',
                component: () => import("@/views/pages/items/quick-edit-items/ItemPriceGroup"),
                name: 'items.quick-edit-price-group.list',
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'quick-edit-item-kits',
                component: () => import("@/views/pages/items/quick-edit-items/ItemKitList"),
                name: 'items.quick-edit-items.item-kit-list',
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'quick-edit-location-items',
                component: () => import("@/views/pages/items/quick-edit-items/LocationList"),
                name: 'items.quick-edit-location-items.list',
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'quick-edit-item-location',
                component: () => import("@/views/pages/items/quick-edit-items/ItemLocationList"),
                name: 'items.quick-edit-item-location.list',
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'quick-edit-tags',
                component: () => import("@/views/pages/items/quick-edit-items/EditTags"),
                name: 'items.quick-edit-items.edit-tags',
                meta: {
                    middleware: [auth]
                }
            },
            {
                path: 'categories',
                component: () => import('@/views/pages/items/categories/List'),
                name: 'items.categories.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'unit-of-measure',
                component: () => import('@/views/pages/items/unit-of-measure/List'),
                name: 'items.unit-of-measure.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'unit-of-measure/create',
                component: () =>
                    import('@/views/pages/items/unit-of-measure/Form'),
                name: 'items.unit-of-measure.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'unit-of-measure/:id/edit',
                component: () =>
                    import('@/views/pages/items/unit-of-measure/Form'),
                name: 'items.unit-of-measure.edit',
                meta: {
                    middleware: [auth, routePermission],
                },

            },

            {
                path: 'item-kits',
                component: () => import('@/views/pages/items/item-kits/List'),
                name: 'items.item-kits.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item-kits/create',
                component: () => import('@/views/pages/items/item-kits/Form'),
                name: 'items.item-kits.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'item-kits/:id/edit',
                name: 'items.item-kits.update',
                component: () => import('@/views/pages/items/item-kits/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'receiving',
                component: () => import('@/views/pages/items/receiving/List'),
                name: 'items.receiving.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'receiving/create',
                component: () => import('@/views/pages/items/receiving/Form'),
                name: 'items.receiving.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'receiving/:id/edit',
                component: () =>
                    import('@/views/pages/items/receiving/Form'),
                name: 'items.receiving.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'purchase-order-drafts',
                name: 'items.purchase-order-drafts.list',
                component: () => import('@/views/pages/items/purchase-order-drafts/List'),
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'purchase-order-drafts-create',
                name: 'items.purchase-order-drafts.create',
                component: () => import('@/views/pages/items/purchase-order-drafts/Create'),
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'purchase-orders-drafts/:id/edit',
                name: 'items.purchase-order-drafts.edit',
                component: () => import('@/views/pages/items/purchase-order-drafts/Create'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'purchase-orders',
                name: 'items.purchase-orders.list',
                component: () => import('@/views/pages/items/purchase-orders/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'purchase-orders/create',
                name: 'items.purchase-orders.create',
                component: () =>
                    import('@/views/pages/items/purchase-orders/Create'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'job-orders',
                name: 'items.job-orders.list',
                component: () => import('@/views/pages/items/job-orders/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'job-orders/create',
                name: 'items.job-orders.create',
                component: () => import('@/views/pages/items/job-orders/Form'),
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'purchase-orders/:id/edit',
                name: 'items.purchase-orders.edit',
                component: () => import('@/views/pages/items/purchase-orders/Create'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'job-orders/:id/edit',
                name: 'items.job-orders.edit',
                component: () => import('@/views/pages/items/job-orders/Form'),
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'stock-transfer',
                component: () =>
                    import('@/views/pages/items/stock-transfer/List'),
                name: 'items.stock-transfer.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'stock-transfer/create',
                component: () =>
                    import('@/views/pages/items/stock-transfer/Form'),
                name: 'items.stock-transfer.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'stock-transfer/:id/edit',
                component: () => import('@/views/pages/items/stock-transfer/Form'),
                name: 'items.stock-transfer.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'stock-take',
                name: 'items.stock-take.list',
                component: () => import('@/views/pages/items/stock-take/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'stock-take/create',
                component: () =>
                    import('@/views/pages/items/stock-take/Form'),
                name: 'items.stock-take.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'stock-take/multiple-item/create',
                component: () =>
                    import('@/views/pages/items/stock-take/multiple-item/Form'),
                name: 'items.stock-take.multiple-item.create',
                // meta: {
                //     middleware: [auth, routePermission],
                // },
            },
            {
                path: 'tags',
                name: 'items.tags.list',
                component: () => import('@/views/pages/items/tags/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'tags/create',
                name: 'items.tags.create',
                component: () => import('@/views/pages/items/tags/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'tags/:id/edit',
                component: () => import('@/views/pages/items/tags/Form'),
                name: 'items.tags.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'price-groups',
                name: 'items.price-groups.list',
                component: () => import('@/views/pages/items/price-groups/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'price-groups/create',
                name: 'items.price-groups.create',
                component: () => import('@/views/pages/items/price-groups/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'price-groups/:id/edit',
                component: () => import('@/views/pages/items/price-groups/Form'),
                name: 'items.price-groups.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'groups',
                name: 'items.groups.list',
                component: () => import('@/views/pages/items/groups/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'groups/create',
                name: 'items.groups.create',
                component: () => import('@/views/pages/items/groups/Form'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'groups/:id/edit',
                component: () => import('@/views/pages/items/groups/Form'),
                name: 'items.groups.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item/stock-report',
                component: () => import('@/views/pages/items/item/StockReport'),
                name: 'items.item.stock-report',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
// COUPONS
            {
                path: 'coupons',
                component: () => import('@/views/pages/items/coupons/CouponsList.vue'),
                name: 'items.coupons.list',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'coupons/create',
                component: () => import('@/views/pages/items/coupons/CouponsForm.vue'),
                name: 'items.coupons.create',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'coupons/:id/edit',
                component: () => import('@/views/pages/items/coupons/CouponsForm.vue'),
                name: 'items.coupons.edit',
                meta: {
                    middleware: [auth],
                },
            },

        ],
    },

    //============== settings
    {
        path: '/settings/',
        component: DefaultLayout,
        children: [
            {
                path: 'employees',
                component: () =>
                    import('@/views/pages/settings/employees/List'),
                name: 'settings.employees.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'employees/create',
                component: () =>
                    import('@/views/pages/settings/employees/Form'),
                name: 'settings.employees.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'employees/:id/edit',
                component: () =>
                    import('@/views/pages/settings/employees/Form'),
                name: 'settings.employees.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'employees/task-reason',
                component: () =>
                    import('@/views/pages/settings/employees/TaskReasonList'),
                name: 'settings.employees.task-reason',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'ecommerce',
                component: () =>
                    import('@/views/pages/settings/ecommerce/List'),
                name: 'settings.ecommerce.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: '/ecommerce/create',
                component: () =>
                    import('@/views/pages/settings/ecommerce/Create'),
                name: 'settings.ecommerce.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: '/ecommerce/logs',
                component: () =>
                    import('@/views/pages/settings/ecommerce/Logs'),
                name: 'settings.ecommerce.logs',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: '/settings/ecommerce/:id/edit',
                component: () =>
                    import('@/views/pages/settings/ecommerce/Create'),
                name: 'settings.ecommerce.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'locations',
                component: () => import('@/views/pages/settings/location/List'),
                name: 'settings.locations.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'locations/create',
                component: () => import('@/views/pages/settings/location/Form'),
                name: 'settings.locations.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'locations/:id/edit',
                component: () => import('@/views/pages/settings/location/Form'),
                name: 'settings.locations.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'store-setting',
                component: () =>
                    import('@/views/pages/settings/store-setting/Form'),
                name: 'settings.store-setting.form',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'store-setting2',
                component: () =>
                    import('@/views/pages/settings/store-setting/Form2'),
                name: 'settings.store-setting.form2',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'taxes',
                component: () => import('@/views/pages/settings/taxes/List'),
                name: 'settings.taxes.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'taxes/create',
                component: () => import('@/views/pages/settings/taxes/Form'),
                name: 'settings.taxes.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'taxes/:id/edit',
                component: () => import('@/views/pages/settings/taxes/Form'),
                name: 'settings.taxes.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'categories',
                component: () => import('@/views/pages/settings/categories/List'),
                name: 'settings.categories.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'logistic-providers',
                component: () => import('@/views/pages/settings/logistic-providers/List'),
                name: 'settings.logistic-providers.list',
                meta: {
                    middleware: [auth, routePermission],
                },

            },

            {
                path: 'v2/logistic-providers',
                component: () => import('@/views/pages/settings/logistic-providers/v2/List'),
                name: 'settings.v2.logistic-providers.list',
                meta: {
                    middleware: [auth, routePermission],
                },

            },


            {
                path: 'logistic-providers/create',
                component: () => import('@/views/pages/settings/logistic-providers/Form'),
                name: 'settings.logistic-providers.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'logistic-providers/:id/edit',
                component: () => import('@/views/pages/settings/logistic-providers/Form'),
                name: 'settings.logistic-providers.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'payment-methods',
                component: () => import('@/views/pages/settings/payment-methods/List'),
                name: 'settings.payment-methods.list',
                meta: {
                    middleware: [auth, routePermission],
                },

            },


            {
                path: 'payment-methods/create',
                component: () => import('@/views/pages/settings/payment-methods/Form'),
                name: 'settings.payment-methods.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'payment-methods/:id/edit',
                component: () => import('@/views/pages/settings/payment-methods/Form'),
                name: 'settings.payment-methods.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'payment-terms',
                component: () => import('@/views/pages/settings/payment-terms/List'),
                name: 'settings.payment-terms.list',
                meta: {
                    middleware: [auth, routePermission],
                },

            },


            {
                path: 'payment-terms/create',
                component: () => import('@/views/pages/settings/payment-terms/Form'),
                name: 'settings.payment-terms.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'payment-terms/:id/edit',
                component: () => import('@/views/pages/settings/payment-terms/Form'),
                name: 'settings.payment-terms.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },


            {
                path: 'scan-types',
                component: () => import('@/views/pages/settings/scan-type/List'),
                name: 'settings.scan-types.list',
                meta: {
                    middleware: [auth, routePermission],
                },

            },

            {
                path: 'scan-types/create',
                component: () => import('@/views/pages/settings/scan-type/Form'),
                name: 'settings.scan-types.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'scan-types/:id/edit',
                component: () => import('@/views/pages/settings/scan-type/Form'),
                name: 'settings.scan-types.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'approval-limits',
                component: () => import('@/views/pages/settings/approval-limits/List'),
                name: 'settings.approval-limits.list',
                meta: {
                    middleware: [auth, routePermission],
                },

            },

            {
                path: 'approval-limits/create',
                component: () => import('@/views/pages/settings/approval-limits/Form'),
                name: 'settings.approval-limits.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'approval-limits/:id/edit',
                component: () => import('@/views/pages/settings/approval-limits/Form'),
                name: 'settings.approval-limits.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'approval-rules',
                component: () => import('@/views/pages/settings/approval-rules/List'),
                name: 'settings.approval-rules.list',
                meta: {
                    middleware: [auth, routePermission],
                },

            },

            {
                path: 'approval-rules/create',
                component: () => import('@/views/pages/settings/approval-rules/Form'),
                name: 'settings.approval-rules.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'approval-rules/:id/edit',
                component: () => import('@/views/pages/settings/approval-rules/Form'),
                name: 'settings.approval-rules.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'job-order-task-types',
                name: 'settings.job-order-task-types.list',
                component: () => import('@/views/pages/settings/job-order-task-types/List'),
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'job-order-task-types/create',
                name: 'settings.job-order-task-types.create',
                component: () => import('@/views/pages/settings/job-order-task-types/Form'),
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'couriers',
                component: () => import('@/views/pages/settings/couriers/List'),
                name: 'settings.couriers.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'couriers/create',
                component: () => import('@/views/pages/settings/couriers/Form'),
                name: 'settings.couriers.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'couriers/:id/edit',
                component: () => import('@/views/pages/settings/couriers/Form'),
                name: 'settings.couriers.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'areas',
                component: () => import('@/views/pages/settings/areas/List'),
                name: 'settings.areas.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'areas/create',
                component: () => import('@/views/pages/settings/areas/Form'),
                name: 'settings.areas.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'areas/:id/edit',
                component: () => import('@/views/pages/settings/areas/Form'),
                name: 'settings.areas.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item-locations',
                component: () => import('@/views/pages/settings/item-locations/List'),
                name: 'settings.item-locations.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item-locations/create',
                component: () => import('@/views/pages/settings/item-locations/Form'),
                name: 'settings.item-locations.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item-locations/:id/edit',
                component: () => import('@/views/pages/settings/item-locations/Form'),
                name: 'settings.item-locations.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'pos-terminals',
                component: () => import('@/views/pages/settings/pos/PosTerminalsList'),
                name: 'settings.pos-terminals',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'pos-terminals/create',
                component: () =>
                    import('@/views/pages/settings/pos/PosTerminalsForm.vue'),
                name: 'settings.pos.pos-terminals-create',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'pos-terminals/:id/edit',
                component: () =>
                    import('@/views/pages/settings/pos/PosTerminalsForm.vue'),
                name: 'settings.pos.pos-terminals-edit',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'lucky-draws-gift-types',
                component: () => import('@/views/pages/settings/lucky-draws-gift-types/List'),
                name: 'settings.lucky-draws-gift-types.list',
                meta: {
                    middleware: [auth, routePermission],
                },

            },

            {
                path: 'lucky-draws-gift-types/create',
                component: () => import('@/views/pages/settings/lucky-draws-gift-types/Form'),
                name: 'settings.lucky-draws-gift-types.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'lucky-draws-gift-types/:id/edit',
                component: () => import('@/views/pages/settings/lucky-draws-gift-types/Form'),
                name: 'settings.lucky-draws-gift-types.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

        ],
    },

    // ============marketing
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {
                path: 'lucky-draws',
                component: () => import('@/views/pages/marketing/lucky-draws/List'),
                name: 'marketing.lucky-draws.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'lucky-draws/create',
                component: () => import('@/views/pages/marketing/lucky-draws/Form'),
                name: 'marketing.lucky-draws.create',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'lucky-draws/:slug/edit',
                component: () => import('@/views/pages/marketing/lucky-draws/Form'),
                name: 'marketing.lucky-draws.edit',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'lucky-draw-users',
                component: () => import('@/views/pages/marketing/lucky-draw-users/List'),
                name: 'marketing.lucky-draw-users.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
        ],
    },

    {
        path: '/integration/',
        component: DefaultLayout,
        children: [
            {
                path: 'quickbook',
                component: () => import('@/views/pages/apps/quickbook'),
                name: 'integrations.quickbook.form',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'error-logs',
                component: () => import('@/views/pages/settings/integration-error-log/List'),
                name: 'integration.errors.list',
            },
        ]
    },
//============ tools (Bunch of scripts to make life easier) ====================

    {
        path: '/tools/',
        component: DefaultLayout,
        children: [
            {
                path: 'list',
                name: 'tools.list',
                component: () => import('@/views/pages/tools/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
        ]
    },

//============ Dashboard ====================

    {
        path: '/module-dashboards/',
        component: DefaultLayout,
        children: [

            {
                path: 'sales',
                component: () => import('@/views/pages/dashboard/sales_dashboard'),
                name: 'module-dashboards.sales-dashboard',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'sales-reps',
                component: () => import('@/views/pages/dashboard/sales_reps'),
                name: 'module-dashboards.sales-rep-dashboard',
                meta: {
                     middleware: [auth],
                },
            },
            {
                path: 'customer-analysis',
                component: () => import('@/views/pages/dashboard/customers-dashboard'),
                name: 'module-dashboards.customer-analysis-dashboard',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'items',
                component: () => import('@/views/pages/dashboard/items-dashboard'),
                name: 'module-dashboards.items-dashboard',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'debtors',
                component: () => import('@/views/pages/dashboard/debtors-dashboard'),
                name: 'module-dashboards.debtors-dashboard',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'purchase-orders',
                component: () => import('@/views/pages/dashboard/purchase-order-dashboard'),
                name: 'module-dashboards.purchase-orders-dashboard',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'job-orders',
                component: () => import('@/views/pages/dashboard/job-order-dashboard'),
                name: 'module-dashboards.job-orders-dashboard',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'item-performance',
                component: () => import('@/views/pages/dashboard/item-performance'),
                name: 'module-dashboards.item-performance',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'recent-item-sales-analysis',
                name: 'module-dashboards.recent-item-sales-analysis',
                component: () => import('@/views/pages/dashboard/recent-item-sales-analysis'),
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'transaction-summary',
                name: 'module-dashboards.transaction-summary',
                component: () => import('@/views/pages/dashboard/transaction-summary'),
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'fulfillment',
                name: 'module-dashboards.fulfillment',
                component: () => import('@/views/pages/dashboard/fulfillment'),
                meta: {
                    middleware: [auth],
                },
            },


        ]
    },

//============ scan station =====================
    {
        path: '/scan-station/',
        component: DefaultLayout,
        children: [
            {
                path: 'scan',
                name: 'scan-station.scan.list',
                component: () => import('@/views/pages/scanner/List'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'scan/invoice',
                name: 'scan-station.scan.invoice',
                component: () => import('@/views/pages/scanner/ScanInvoice'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'scan/proforma',
                name: 'scan-station.scan.proforma-invoice',
                component: () => import('@/views/pages/scanner/ScanProformaInvoice'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'scan/ecommerce',
                name: 'scan-station.scan.ecommerce-invoice',
                component: () => import('@/views/pages/scanner/ScanEcommerceInvoice'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'scan/delivery-order',
                name: 'scan-station.scan.delivery-order',
                component: () => import('@/views/pages/scanner/ScanDeliveryOrder'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'scan/stock-transfer',
                name: 'scan-station.scan.stock-transfer',
                component: () => import('@/views/pages/scanner/ScanStockTransfer'),
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'scan/deactive-barcode',
                component: () => import("@/views/pages/scanner/DeactiveBarcode"),
                name: 'scan-station.scan.deactive-barcode',
                meta: {
                    middleware: [auth, routePermission]
                }
            },
            {
                path: 'scan/stock-count',
                component: () => import("@/views/pages/scanner/ScanStockCount"),
                name: 'scan-station.scan.stock-count',
                meta: {
                    middleware: [auth, routePermission]
                }
            },
            {
                path: 'scan/job-order',
                component: () => import("@/views/pages/scanner/ScanJobOrder"),
                name: 'scan-station.scan.job-order',
                meta: {
                    middleware: [auth, routePermission]
                }
            },

            {
                path: 'scan/awb',
                component: () => import("@/views/pages/scanner/ScanAwbDoc"),
                name: 'scan-station.scan.awb',
                meta: {
                    middleware: [auth, routePermission]
                }
            },
        ],
    },

    //======= Collaboration
    {
        path: '/collaboration/',
        component: DefaultLayout,
        children: [
            {
                path: 'user-assigned-tasks',
                component: () => import('@/views/pages/collaboration/tasks/UserAssignedTasks.vue'),
                name: 'collaboration.tasks.user-assigned',
                meta: {
                    middleware: [auth],
                },
            },
            ],
    },

    //======= reports
    {
        path: '/reports/',
        component: DefaultLayout,
        children: [
            {
                path: 'list',
                component: () => import('@/views/pages/reports/List'),
                name: 'reports.list',
                meta: {
                    middleware: [auth, routePermission],
                },
            },

            {
                path: 'category-sales',
                component: () => import('@/views/pages/reports/category-sales.vue'),
                name: 'reports.category-sales',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'sales',
                component: () => import('@/views/pages/reports/sales/sales-report'),
                name: 'reports.sales',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'item-analysis-by-sales-transaction',
                component: () => import('@/views/pages/reports/sales/item-analysis-by-sales-transaction'),
                name: 'reports.sales.item-analysis-by-sales-transaction',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'customers',
                component: () => import('@/views/pages/reports/customers/sales'),
                name: 'reports.customers',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'account-statement',
                component: () => import('@/views/pages/reports/account-statement'),
                name: 'reports.account-statement',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'job-orders',
                component: () => import('@/views/pages/reports/job-orders/List'),
                name: 'reports.job-orders',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'inventory-snapshot',
                component: () => import('@/views/pages/reports/inventory-snapshot'),
                name: 'reports.inventory-snapshot',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'low-inventory',
                component: () => import('@/views/pages/reports/low-inventory'),
                name: 'reports.low-inventory',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'scan-stock-count',
                component: () => import('@/views/pages/reports/scan-stock-count'),
                name: 'reports.scan-stock-count',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'scan-stock-count-detail/:uid/:date',
                component: () => import('@/views/pages/reports/scan-stock-count-detail'),
                name: 'reports.scan-stock-count-detail',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'negative-stock',
                component: () => import('@/views/pages/reports/item-negative-stock'),
                name: 'reports.negative-stock',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'accounts/customers-outstanding',
                component: () => import('@/views/pages/reports/accounts/customers-outstanding'),
                name: 'reports.accounts.customers-outstanding',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            // {
            //     path: 'items/item-sales',
            //     component: () => import('@/views/pages/reports/items/item-sales'),
            //     name: 'reports.items.item-sales',
            //     meta: {
            //         middleware: [auth, routePermission],
            //     },
            // },
            {
                path: 'sales/scan-invoice',
                component: () => import('@/views/pages/reports/sales/scan-invoice'),
                name: 'reports.sales.invoice-scan',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'sales/scan-ecommerce-invoice',
                component: () => import('@/views/pages/reports/sales/scan-ecommerce-invoice'),
                name: 'reports.sales.ecommerce-invoice-scan',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'sales/ecommerce-sales',
                component: () => import('@/views/pages/reports/sales/ecommerce-sales'),
                name: 'reports.sales.ecommerce-sales',
                meta: {
                    middleware: [auth, routePermission],
                },
            },
            {
                path: 'sales/ecommerce-sales-date-range',
                component: () => import('@/views/pages/reports/sales/ecommerce-sales-date-range'),
                name: 'reports.sales.ecommerce-sales-date-range',
                meta: {
                    middleware: [auth],
                },
            },

            {
                path: 'awb',
                component: () => import('@/views/pages/reports/awb-doc'),
                name: 'reports.awb',
                meta: {
                    middleware: [auth],
                },
            },

        ],
    },

    {
        path: '/notifications/',
        component: DefaultLayout,
        children: [
        {
            path: 'list',
            component: () => import('@/views/pages/settings/user-notifications/List'),
            name: 'notification.list',
            meta: {
                middleware: [auth],
            },
        },
        ]
    },

    {
        path: '/kahhwa/',
        component: DefaultLayout,
        children: [
            {
                path: 'job-processing-cards/list',
                component: () => import('@/views/pages/kahhwa/job-processing-cards/List'),
                name: 'kahhwa.job-processing-cards.list',
                meta: {
                    middleware: [auth],
                },
            },
            {
                path: 'job-processing-cards/create',
                component: () => import('@/views/pages/kahhwa/job-processing-cards/Form'),
                name: 'kahhwa.job-processing-cards.create',
                meta: {
                    middleware: [auth],
                },
            },


        ]
    }
]

export default routes
